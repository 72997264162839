.MainCard {
  border: 2px solid #016738;
}
.fieldcss {
  margin-top: 15px;
  margin-bottom: 15px;
}
.marginTop5 {
  margin-top: 10px !important;
}
.divscroll {
  overflow: scroll;
  height: 620px;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.resumeTitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #016738;
  font-family: Poppins-Bold;
  font-size: 24px;
  color: #ffffff;
  border-radius: 3px 3px 0 0;
}
.ResumeMainContainer {
  background-color: #ffffff;
}
.marginTop5 {
  margin-top: 10px !important;
}
.form-control {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.marginBottom20 {
  margin-bottom: 20px !important;
}
.carddesign {
  border: 2px solid #016738;
  border-radius: 1.25rem;
}
.mainname {
  margin: 0px !important;
  line-height: 1.2;
  color: #04355f;
}
.introicons {
  float: right;
  margin-bottom: 0px;
}
.subinfo {
  margin-bottom: 0px;
  font-size: 15px;
}
.mainsubinfo {
  color: #04355f;
  margin-bottom: 5px !important;
}

.subtitle {
  color: #04355f;
  font-weight: 600;
}

.fa-linkedin-in {
  color: #04355f;
}
.fa {
  color: #04355f;
}

.aboutme {
  max-width: 400px;
}
.divscroll {
  overflow: scroll;
  height: 800px;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}
input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  /*margin: 20% auto;    */
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: #04355f;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #016738;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #016738;
}

.printbtn {
  background-color: #016738;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
}

/* Darker background on mouse-over */
.printbtn:hover {
  background-color: #016738;
}

.Nav Link {
  background-color: #016738;
}

.navbaritem {
  color: white !important;
  background-color: #016738 !important;
  margin: 5px;
}
.navbar-custom {
  background-color: #e6e7e8 !important;
}
.margintop50 {
  margin-top: 50px;
}

.margin10 {
  margin: 10px;
}
@media print {
  @page {
    size: A3;
  }
  header,
  footer {
    display: none;
  }
  body,
  p {
    font-size: 13px !important;
  }
}
