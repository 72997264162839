@media print {
  @page {
    size: A3;
  }
  header,
  footer {
    display: none;
  }
  body,
  p {
    font-size: 13px !important;
  }
}
