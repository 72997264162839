.style-1 del {
  color: rgba(255, 0, 0, 0.5);
  text-decoration: none;
  position: relative;
}
.style-1 del:before {
  content: " ";
  display: block;
  width: 100%;
  border-top: 2px solid rgba(255, 0, 0, 0.8);
  height: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(-7deg);
}
.style-1 ins {
  color: green;
  font-size: 32px;
  text-decoration: none;
  padding: 1em 1em 1em 0.5em;
}
/* CustomTooltip.css */
.custom-tooltip {
  background-color: yellow !important;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
}
