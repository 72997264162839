@use "../abstract" as *;

/*----------------------------------------*/
/*  18. SLIDER CSS START
/*----------------------------------------*/

.slider {
  &__wrapper {
    & .swiper-slide-active {
      & span,
      & p,
      & a,
      & .slider__title {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &__area {
  }
  &__height {
    //min-height: 950px;
    min-height: 60px;
    @media #{$sm} {
      min-height: 700px;
    }
    @media #{$xs} {
      min-height: 600px;
    }
  }
  &__overlay {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: $black-3, $alpha: 0.66);
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    & span {
      font-size: 18px;
      color: $e-yellow;
      margin-bottom: 12px;
      display: inline-block;
    }

    & p {
      font-size: 22px;
      line-height: 30px;
      padding-right: 160px;
      margin-bottom: 28px;
      opacity: 0.7;
      color: $white;

      @media #{$md} {
        padding-right: 0;
      }
      @media #{$sm} {
        padding-right: 0;
      }
      @media #{$xs} {
        padding-right: 0;
      }
    }
    & > span,
    & p,
    & a {
      position: relative;
      z-index: 1;
      padding-right: 25px;
      transform: translateY(70px);
      transition: 1s;
      opacity: 0;
    }
  }
  &__title {
    font-size: 70px;
    color: $white;
    line-height: 1.05;
    margin-bottom: 5px;

    @media #{$md} {
      font-size: 50px;
    }
    @media #{$sm} {
      font-size: 40px;
    }
    @media #{$xs} {
      font-size: 30px;
    }
    position: relative;
    z-index: 1;
    padding-right: 25px;
    transform: translateY(70px);
    transition: 1s;
    opacity: 0;
    & span {
      font-size: 70px;
      color: $white;
      position: relative;
      margin-bottom: 0;
      @media #{$md} {
        font-size: 50px;
      }
      @media #{$sm} {
        font-size: 40px;
      }
      @media #{$xs} {
        font-size: 30px;
      }
      & img {
        position: absolute;
        left: -14px;
        bottom: 11px;
        z-index: -1;
        -webkit-animation: section-animation 3s infinite;
        animation: section-animation 3s infinite;
        @media #{$xs} {
          height: 15px;
          bottom: 0;
        }
      }
    }
  }
  &__btn {
    height: 50px;
    line-height: 48px;
    border: 2px solid rgba($color: $white, $alpha: 0.3);
    padding: 0 22px;
    background: transparent;

    &:hover {
      background: $white;
      color: $black-3;
      border-color: $white;
    }
  }
  &__nav {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    &-item {
      @include background();
      height: 100px;
      transition: height 0.3s ease-in-out;
      position: relative;

      &:hover {
        cursor: pointer;
      }
      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: $e-orange-4, $alpha: 0.8);
      }
      &.blue-bg {
        &::after {
          background: rgba($color: $e-blue-4, $alpha: 0.8);
        }
      }
      &.pink-bg {
        &::after {
          background: rgba($color: $e-pink-3, $alpha: 0.8);
        }
      }
      &.green-bg {
        &::after {
          background: rgba($color: $e-green-2, $alpha: 0.8);
        }
      }
      &.swiper-slide-thumb-active {
      }
    }
    &-content {
      position: relative;
      z-index: 1;
      padding: 23px 50px;
      @media #{$xl} {
        padding-left: 25px;
        padding-right: 25px;
      }
      @media #{$lg} {
        padding: 15px;
      }
      @media #{$md} {
        padding: 15px;
      }
      & span {
        display: inline-block;
        font-size: 15px;
        color: $white;
        opacity: 0.8;
      }
      & h4 {
        font-size: 22px;
        font-weight: 600;
        color: $white;
        margin-bottom: 0;
      }
    }
  }
}

.brand__slider .swiper-wrapper {
  align-items: center;
}
