.Row2MainCol {
  background-color: #ffff !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

ul {
  list-style: none;
}

/* ul li::before {
    content: '\2022';
    color: #016738;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  } */
.icons-container {
  padding: 20px;
  text-align: center;
}
.greentexthighligher {
  color: #016738;
  font-weight: bold;
}

.subtitle {
  font-weight: bold;
  padding-left: 16px;
  padding-top: 10px;
}

.servicedescription {
  display: none !important;
}

.bannerbackgroud {
  background-image: "/images/herosvg.svg";
  height: 200px;
}

.bannrcontainer {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.bannrcontainer img {
  width: 100%;
  height: auto;
}

.bannrcontainer .bannerbtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.bannerbg {
  background-color: #ffffff !important;
  cursor: pointer;
}
.react-multi-carousel-dot {
  display: none;
}
.ytp-overflow-icon {
  display: none !important;
}
.ytp-embed:not(.ad-showing).ytp-small-mode .ytp-overflow-button {
  display: none !important;
}
