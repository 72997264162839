.fieldcontainer {
  margin-top: 10;
  margin-bottom: 10;
}
.labelContainer {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 300px; */
  margin: 10px 0px;
}
.mentorlabelitem {
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  line-height: 1;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 2px;

  border-radius: 1000px;
}
.my-e-btn {
  display: inline-block;
  height: 50px;
  line-height: 52px;
  text-align: center;
  padding: 0 25px;
  color: #ffffff;
  background: #2b4eff;
  border-radius: 4px;
  font-weight: 500;
}
