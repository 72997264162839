.playlistcard {
  margin: 10px;
  border: 0;
}
.videoMainCard {
  margin: 20px;
}
.transparentlabelitem {
  margin-bottom: 5px;
  border: 2px solid #1967d2;
  background: #fff;
  /* color: #8d97a1; */
  color: black;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  text-align: center !important;
}

.mentorname {
  color: #8d97a1;
}
.smalltransparentlabelitem {
  margin: 5px;
  border: 2px solid #016738 !important;
  background: #fff;
  /* color: #8d97a1; */
  color: black;
  border-radius: 5px;
  width: 80px;
  text-align: center !important;
}
.flexcontainer {
  display: flex;
  flex-wrap: wrap;
}
.flexitem {
  display: flex;
  margin-right: 10px;
}
.coursetransparentlabelitem {
  margin: 5px;
  border: 2px solid #016738 !important;
  background: #fff;
  /* color: #8d97a1; */
  color: black;
  border-radius: 5px;
  width: 120px;
  text-align: center !important;
}
.aboutcoursetitle {
  font-size: 30px;
}
.coursetitle {
  font-size: 25px;
}
.mentornametitle {
  font-size: 22px;
}
.maintitle {
  font-weight: 700;
  line-height: 1.2;

  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stylishUnderline {
  border: 4px solid #fb9200;
  width: 45%;
}
.divscroll {
  overflow: scroll;
  height: 600px;
  overflow-x: hidden;
}
.singlevideoList {
  cursor: pointer;
}

.singleCard {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.learnlabelContainer {
  display: flex;
  flex-wrap: wrap;
}
.mobile {
  display: none !important;
}

/* If the screen size is 600px wide or less, hide the element */
@media only screen and (max-width: 600px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}
