.GhScoreContainer{
  width: 100%;
  height: 272px;
  background: #ffffff;
  box-shadow: 10px 30px 40px rgba(221, 219, 214, 0.46);
  border-radius: 3px;
  font-family: Poppins-Regular;
  color: rgba(61, 41, 36, 0.8);
  font-weight: 600;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0 28px 0;
}