Card {
  border: 0px !important;
}
.profileCard {
  border: 0px !important;
}

.profileTitle {
  color: #3d2924cc;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 20px;
  padding: 19px 21px;
}

@media only screen and (max-width: 600px) {
  .mobileMargin {
    margin-top: 10px;
  }
}

.LanguageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  /* &:hover {
    img {
      opacity: 1;
    }
  }
  img {
    padding-top: 6px;
    opacity: ${({ idx }) => (idx === 0 ? 1 : 0)};
    cursor: pointer;
  } */
}
.PillContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 90px;
  font-family: Poppins-Regular;
  font-style: normal;
  font-size: 14px;
  color: rgba(1, 103, 56, 0.94);
  /* div:nth-child(1) {
    -webkit-transition: background-color 1.5s linear;
    -ms-transition: background-color 1.5s linear;
    transition: background-color 1.5s linear;
    background-color: ${({ isDisabled }) =>
      isDisabled ? "#C4C4C4" : "#17b978"};
  } */
}
