.carddesign {
  margin: 5px;
}
.videogallerytitle {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  margin-top: 5px;
}
.placementtoolcontainer {
  margin: 20px;
}

.AssistmentApp {
  text-align: center;
}

.question-card {
  /* Center the div  */
  margin: 0 auto;

  /* Dimensions  */
  width: 95%; /* Can be in percentage also. */
  height: auto;

  /* Visual  */

  padding: 16px;
  border-radius: 16px;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.question-text {
  color: darkblue;
  font-size: 24px;
}

/* Final Results  */

.final-results {
  /* Center the div  */
  margin: 0 auto;

  /* Dimensions  */
  width: 95%; /* Can be in percentage also. */
  height: auto;
  margin-top: 10px;

  /* Visual  */

  padding: 16px;
  border-radius: 16px;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.customtextbtn {
  width: 100%;
  margin: 10px;
}
.easytest {
  color: #0f5132;
}

.testtitlebox {
  padding: 20px;
  font-weight: 600;
  font-size: large;
}

h1 {
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 40px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
.player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}
/* .react-player__preview {
  max-width: 400;
  max-height: 400;
} */
