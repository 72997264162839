.App {
  overflow-x: hidden;
}
.resumeTitle {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  background: #016738;
  font-family: Poppins-Bold;
  font-size: 24px;
  color: #ffffff;
  border-radius: 3px 3px 0 0;
}
.ResumeMainContainer {
  background-color: #ffffff;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.marginTop5 {
  margin-top: 10px !important;
}
.form-control {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.marginBottom20 {
  margin-bottom: 40px !important;
}
.carddesign {
  border: 2px solid #016738;
  border-radius: 1.25rem;
}
.mainname {
  margin: 0px !important;
  line-height: 1.2;
  color: #04355f;
}
.introicons {
  float: right;
  margin-bottom: 0px;
}
.subinfo {
  margin-bottom: 0px;
  font-size: 15px;
}
.mainsubinfo {
  color: #04355f;
  margin-bottom: 5px !important;
}

.resumesubtitle {
  color: #04355f;
  font-weight: 600;
}

.fa-linkedin-in {
  color: #04355f;
}
.fa {
  color: #04355f;
}

.divscroll {
  overflow: scroll;
  height: 800px;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}
input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  /*margin: 20% auto;    */
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: #04355f;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2b4eff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2b4eff;
}

.printbtn {
  background-color: #016738;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
}

/* Darker background on mouse-over */
.printbtn:hover {
  background-color: #016738;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

tr.border-bottom td {
  border-bottom: 2px solid #e0e0e0;
}

* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
}

.column30 {
  float: left;
  width: 30%;
  padding: 10px;
}
.column70 {
  float: left;
  width: 70%;
  padding: 10px;
}
.columntopleft {
  float: left;
  width: 35%;
  padding: 10px;
}
.columntopright {
  float: left;
  width: 65%;
  padding: 10px;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
p {
  display: block !important;
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}
.column30 {
  padding: 5px !important;
}
.column70 {
  padding: 5px !important;
}
/* p {
    font-size: 13px !important;
  }
  div {
    font-size: 13px !important;
  } */
@media print {
  @page {
    size: A4;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  header,
  footer {
    display: none;
  }
  body,
  p {
    font-size: 18px !important;
  }
  /* h1 {
      line-height: 0.8;
      font-size: 2.5rem;
    } */
  .educationrow {
    margin-bottom: 0px !important;
  }
  .column30 {
    padding: 5px !important;
  }
  .column70 {
    padding: 5px !important;
  }
  .App {
    margin: 20px !important;
  }
  .titleheading {
    margin-top: 10px;
    color: #28b4a3;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 14px;
  }
  .subtitleheading {
    margin-top: 10px;
    color: #28b4a3;
    font-weight: 700;
    font-size: 12px !important;

    line-height: 14px;
  }
  .descriptionfontsize {
    font-size: 12px !important;
    line-height: 14px;
  }
  .hrstyle {
    color: #0ab421;
    background-color: #0ab421;
    border-top: 2px solid #0ab421;
    margin-top: 0;
    height: 3;
  }
  .educationrow {
    margin-bottom: 10px !important;
  }
  .printprofile {
    width: 115px !important;
    height: 115px !important;
    border-radius: 50% !important;
  }
  .op5-profile {
    width: 115px !important;
    height: 115px !important;
  }
  .aboutmeresume {
    text-align: justify;
  }
  li p {
    position: relative;
    left: -5px !important;
    line-height: 14px !important;
  }
  .option5-title {
    margin-top: 10px;
    color: #28ae71;
    font-weight: 700;
    font-size: 13px !important;
  }
  .op5-icon {
    color: #28ae71;
  }
}

.resumetransparentlabelitem {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 10px;
  font-weight: 900;
  line-height: 1;
  font-size: 18px;
  margin-right: 8px;
  margin-bottom: 5px;
  border: 2px solid #28b4a3;
  background: #28b4a3;
  color: white;
  border-radius: 5px;
}

.titleheading {
  margin-top: 10px;
  color: #28b4a3;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 16px;
}
.subtitleheading {
  margin-top: 10px;
  color: #28b4a3;
  font-weight: 700;
  font-size: 12px !important;

  line-height: 14px;
}
.descriptionfontsize {
  font-size: 12px !important;
  line-height: 14px;
}

/* .pcircle::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #28b4a3;
} */

.hrstyle {
  color: #0ab421;
  background-color: #0ab421;
  border-top: 2px solid #0ab421;
  margin-top: 0;
  height: 3;
}
.printprofile {
  width: 140px;
  height: 140px;
  border-radius: 50% !important;
}
.op5-profile {
  width: 140px;
  height: 140px;
}
.aboutmeresume {
  text-align: justify;
}
li p {
  position: relative;
  left: -5px;
  line-height: 14px !important;
}
.op5-title {
  margin-top: 10px;
  color: #28ae71;
  font-weight: 700;
  font-size: 16px;
}
.op5-pill {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 10px;
  font-weight: 900;
  line-height: 1;
  font-size: 18px;
  margin-right: 8px;
  margin-bottom: 5px;
  border: 2px solid #28ae71;
  background: #28ae71;
  color: white;
  border-radius: 5px;
}
.op5-sub {
  margin-top: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000;
}
.op5-sub-sub {
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000;
}
.op5-date {
  color: #b1b1b1;
}
.op5-dash {
  color: #28ae71;
  font-weight: 700;
  font-size: 22px;
}
.op5-icon {
  color: #28ae71;
}
.op5-titlespacing {
  margin-top: 10px !important;
  margin-bottom: 5px;
}
