.progressbarcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 35px;
  background: #ffffff;
  justify-content: center;
}
.allprogressbar {
  width: 100%;
  display: flex;
  padding: 20px;
  border: 1px solid #c4c4c4;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.11);
  margin-top: 22px;
}
.individualprogressblock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
@media print {
  .printprogressbar {
    max-width: 90% !important;
  }
  .SoftSkillsContainer {
    margin-top: 200px;
  }
  .score-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .score-marker {
    font-size: 18px;
    margin: 0 2px;
    color: #ccc;
  }

  .score-marker.active {
    color: #ff9900; /* Change the color for the active marker */
    cursor: pointer;
  }

  .score-marker.active:hover::after {
    content: attr(title);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background-color: #ff9900;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    opacity: 0.9;
  }
  .progress-bar {
    position: relative;
  }

  .marker {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 2px;
    background-color: red;
  }

  .tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 0.9;
  }
  .progress-bar::after {
    display: block !important;
  }

  .tooltip {
    display: block !important;
  }
  .page-section-breaker {
    margin-top: 120px !important;
  }
  .bar-text {
    margin-top: -10px;
    font-size: 14px !important;
  }
  .page-section-breaker-open {
    margin-top: 30px !important;
  }
}

.HeadText {
  display: flex;
  width: 100%;
  padding-left: 25px;
  box-shadow: 0 3px 3px 3px #c4c4c4;
  color: #3e3d43;
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 30px;
  padding-top: 30px;
}

.score-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-marker {
  font-size: 18px;
  margin: 0 2px;
  color: #ccc;
}

.score-marker.active {
  color: #ff9900; /* Change the color for the active marker */
  cursor: pointer;
}

.score-marker.active:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: #ff9900;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  opacity: 0.9;
}
.progress-bar {
  position: relative;
}

.marker {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 10px;
  background-color: red;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0.9;
}
.main-row-container {
  padding-top: 15px;
  border: 1px solid #c4c4c4;
  padding: 20px;
  margin-top: 10;
}
.span-heading {
  color: #5a974f;
  font-weight: 600;
  font-size: 19px;
}
.span-color-text-green {
  color: #5a974f;
  font-weight: 400;
}
.score-li {
  list-style-type: circle;
  color: black;
  margin-left: 20px;
}
.p-subtitle {
  font-weight: 600;
  color: black;
}

.progress-bar {
  position: relative;
}

.progress-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, red 50%, transparent 50%);
  background-position: 100% 0;
  background-size: 2px 100%;
  background-repeat: no-repeat;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0.9;
}
.text-container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 45px;
  padding-right: 45px;
  margin-left: 40px;
  margin-right: 40px;
  border: 1px solid #c4c4c4;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.11);
  background: #ffffff !important;
  color: black !important;
}
.sub-title-text {
  color: black !important;
  font-size: 18px;
}
.main-name {
  color: black !important;
  font-weight: 900;
}
.score-title {
  color: black !important;
  font-weight: 900;
  font-size: 18px;
}
.sub-description-text {
  color: black !important;
}
.page-section-breaker {
}
